<template lang="pug">
section.recipes-widget(:class='"recipes-widget--" + displayType', v-if='computedItems.length')
  .recipes-widget__container
    p.recipes-widget__title(v-if='title') {{ title }}
    .recipes-widget__items
      .recipes-widget__item(v-for='item in computedItems', :key='`recipe-${item.postId}`')
        AdminControlsPanel.recipes-widget__item-admin-panel(
          v-if='showAdminControlsPanel && displayType === "big-tiles"',
          :viewsCount='getViewsCount(item.postId)',
          :editLinkHref='getEditLinkHref(item.postId)',
          :loading='getLoadingViews(item.postId)',
          @click-on-restore-button='updateViewsCount(item.postId)'
        )
        AppPicture.recipes-widget__item-picture(
          v-if='item.images.length',
          :alt='item.title',
          :images='item.images',
          :defaultImages='item.images',
          :sizesSliced='[72, 56, 284, 368]',
          :sizesByDevice='{ mobile: "56px", tablet: "284px" }'
        )
        .recipes-widget__item-name {{ item.title }}
        a.recipes-widget__item-link(:href='item.link', @click='onClickRecipe(item.link)', target='_blank')
    a.recipes-widget__show-more-button(
      :href='computedMoreLink',
      target='_blank',
      v-if='computedMoreLink',
      @click='onShowMore'
    ) Показать ещё
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import AdminControlsPanel from '@/components/lh-ui/AdminControlsPanel/AdminControlsPanel.vue';
  import { getRelativeLink, getEditLinkHref, NOOP } from '~/utils';
  import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';
  import { useArticlesStore } from '~/store/articles';
  import { useRecipesListWidgetStore } from '~/store/recipesListWidget';
  import { useIndexStore } from '~/store';
  import { useUpdatingViewsCount } from '@/composables/useUpdatingViewsCount';

  export default defineNuxtComponent({
    name: 'RecipesWidgetContainer',

    components: {
      AdminControlsPanel,
      AppPicture,
    },

    setup() {
      const { loadingViewsIds, updateViewsCount, getLoadingViews } = useUpdatingViewsCount();

      return {
        loadingViewsIds,
        updateViewsCount,
        getLoadingViews,
      };
    },

    props: {
      title: {
        type: String,
        required: true,
      },
      items: {
        type: Array as PropType<WidgetRecipe[]>,
        required: true,
      },
      displayType: {
        type: String as PropType<'default' | 'big-tiles'>,
        default: 'default',
      },
      moreLink: {
        type: [String, null] as PropType<string | null>,
        default: null,
      },
      recipesWidgetEventCategory: {
        type: String,
        required: true,
      },
      list: {
        type: String as PropType<'similar' | 'custom' | 'related'>,
        required: true,
      },
    },

    computed: {
      computedItems(): WidgetRecipe[] {
        switch (this.list) {
          case 'custom':
            return this.items;
          case 'similar':
            return useRecipesListWidgetStore().currentSimilarRecipes;
          case 'related':
            return useRecipesListWidgetStore().currentRelatedRecipes;
          default:
            return this.items;
        }
      },
      computedMoreLink(): string {
        switch (this.list) {
          case 'custom':
            return this.moreLink ?? '';
          case 'similar':
            return useRecipesListWidgetStore().currentSimilarLink;
          case 'related':
            return useRecipesListWidgetStore().currentRelatedLink;
          default:
            return this.moreLink ?? '';
        }
      },
      showAdminControlsPanel(): boolean {
        return useIndexStore().isAuthenticatedInWordpress;
      },
    },

    methods: {
      NOOP,
      getRelativeLink,
      getEditLinkHref,

      getViewsCount(id: number) {
        return useArticlesStore().getViewsCount(id);
      },

      onShowMore(): void {
        if (this.computedMoreLink) {
          const slug = getRelativeLink(this.computedMoreLink);
          this.$emit('show-more', {
            slug,
            category: this.recipesWidgetEventCategory,
          });
        }
      },
      onClickRecipe(link: string): void {
        const slug = getRelativeLink(link);
        this.$emit('click-recipe', {
          slug,
          category: this.recipesWidgetEventCategory,
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  $bg-color: #f5f5f5;
  $main-color-dark: #e53c35;
  @mixin multilineEllipsis($lines: 3) {
    -webkit-box-orient: vertical;
    object-fit: contain;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    line-clamp: $lines;
    box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .recipes-widget {
    &__title {
      margin-bottom: 4px;

      @include fontH4;
      @include padding-x(16px);
      @include multilineEllipsis(2);
    }

    &__container {
      background: white;
      padding: 20px 0 0;
      position: relative;
      border: 1px solid $borderColor;

      &:before {
        content: '';
        height: 3px;
        display: block;
        background: $main;
        position: absolute;
        width: calc(100% + 2px);
        left: -1px;
        top: -1px;
        right: 0;
      }
    }

    &__item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 16px;
      background: white;

      @include removeHyperlinkFormatting;

      &:not(:last-child) {
        // border-bottom: 1px solid transparent;
        &::before {
          content: '';
          position: absolute;
          width: calc(100% - 32px);
          left: 16px;
          height: 1px;
          background: $borderColor;
          bottom: -1px;
          z-index: 1;
        }
      }
    }

    &__item-link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &__item-admin-panel {
      display: none;
    }

    &__item-picture {
      flex: 0 0 56px;
      height: 56px;
      margin-right: 16px;
      position: relative;
      overflow: hidden;
      background: $bg-color center top / cover no-repeat;
      border-radius: 2px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    &__item-name {
      cursor: pointer;

      @include fontTextBtn;
      @include multilineEllipsis(2);
    }

    &__show-more-button {
      @include removeHyperlinkFormatting;
      @include fontTextBtn;

      & {
        width: calc(100% - 32px);
        height: 48px;
        background: $main;
        border-radius: 2px;
        cursor: pointer;
        margin: 8px 16px 16px;
        display: block;
        text-align: center;
        color: white;
        line-height: 48px;
      }

      @include hover {
        background: $main-color-dark;
      }
    }

    &.recipes-widget--default {
      .recipes-widget {
        &__item {
          position: relative;
          @include hover {
            &::after {
              $hoverBorderWidth: 2px;
              $containerBorderWidth: 1px;
              $borderBottomWidth: 1px;

              content: '';
              box-sizing: border-box;
              position: absolute;
              width: calc(100% + (#{$containerBorderWidth} * 2));
              height: calc(100% + #{$borderBottomWidth});
              left: -#{$containerBorderWidth};
              top: 0;
              border: $hoverBorderWidth solid $main;
              z-index: 1;
            }
          }
        }
      }
    }

    &.recipes-widget--big-tiles {
      .recipes-widget {
        @include tablet {
          background: white;

          &__title {
            margin-bottom: 24px;

            @include fontH4(true);
            @include padding-x(0);

            @include tablet {
              @include fontH3;
            }
          }

          &__container {
            padding: 16px 24px 0;
          }

          &__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }

          &__item {
            display: block;
            height: auto;
            flex: 0 0 284px;
            margin-bottom: 34px;
            padding: 0;

            &::before,
            &::after {
              display: none;
            }

            @include hover {
              .recipes-widget__item-name {
                color: $main;
              }
              :deep(.recipes-widget__item-picture img) {
                transform: scale(1.1);
              }
            }

            // если количество элементов нечетное, скрыть последний элемент
            &:last-child:nth-child(odd) {
              display: none;
            }
          }

          &__item-admin-panel {
            display: flex;
            position: absolute;
            z-index: 2;
            left: 4px;
            top: 4px;
          }

          &__item-picture {
            display: block;
            width: 284px;
            height: 142px;
            margin: 0 0 8px 0;
            overflow: hidden;

            :deep(img) {
              transform: none;
              position: static;
              transition: transform 0.3s ease-in-out;
            }
          }

          &__item-name {
            @include fontH4;
          }

          &__show-more-button {
            @include fontTextBtn;
            @include margin-x(0);

            & {
              color: white;
              margin-top: -8px;
              margin-bottom: 40px;
              line-height: 48px;
              width: 100%;
            }
          }
        }
      }
    }
  }
</style>
